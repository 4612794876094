import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent implements OnInit {
  loading: boolean = false;

  constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.getSpinnerObservable().subscribe((loading) => {
      this.loading = loading;
    });
  }
}
