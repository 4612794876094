import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AppServiceService } from '../../services/app-service.service';
import { Subscription } from 'rxjs';
import { OrderUpdateService } from '../../services/order-update.service';
import { EncryptionService } from '../../encryption.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnDestroy {
  isSidenavOpen = false;
  noTable = false;
  cartItemCount = 0;
  hasActiveOrders = false;
  private orderUpdateSubscription: Subscription;

  constructor(
    private router: Router,
    private app: AppServiceService,
    private encryptionService: EncryptionService,
    private orderUpdateService: OrderUpdateService
  ) {
    this.orderUpdateSubscription = this.orderUpdateService.orderUpdate$.subscribe(() => {
      this.checkActiveOrders();
    });
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  ngOnInit(): void {
    this.updateCartItemCount();

    // Listen for cart updates and update the item count
    this.app.cartUpdated.subscribe(() => {
      this.updateCartItemCount();
    });

    this.checkActiveOrders();
  }

  goToBuzz() {
    this.router.navigateByUrl('/buzz');
  }

  updateCartItemCount() {
    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    this.cartItemCount = cart.length;
  }

  checkActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      this.hasActiveOrders = false;
      return;
    }

    this.app.fetchActiveOrders(this.encryptionService.decrypt(tableNumber)).subscribe({
      next: (res: any) => {
        this.hasActiveOrders = res.data && res.data.length > 0;
      },
      error: (err: any) => {
        console.log(err);
        this.hasActiveOrders = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.orderUpdateSubscription) {
      this.orderUpdateSubscription.unsubscribe();
    }
  }
}
