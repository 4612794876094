import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AppServiceService } from '../services/app-service.service';
import { EncryptionService } from '../encryption.service';
import { PusherService } from '../services/pusher.service';
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  newMessage = '';
  activeOrder: any;
  chatHistory: any;
  constructor(
    private app: AppServiceService, private encryptionService: EncryptionService, private route: ActivatedRoute, private pusherService: PusherService) { }
  // Non-null assertion operator (!)
  ngOnInit(): void {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {

    } else {
      // let tabili = this.encryptionService.decrypt(tableNumber);
      // let message = `table-notification-${tabili}`;
      // this.pusherService.channel.bind(message, (data: any) => {
      //   if (data.message) {
      //     this.chatHistory.push(data.message);
      //     setTimeout(() => this.scrollToBottom(), 100);
      //     this.playBeep();
      //     // this.buzzNotification = data;
      //   }
      // });
      this.fetchActiveOrders();
    }
    this.fetchActiveOrders();
  }
  @ViewChild('chatBody', { static: true }) chatBody!: ElementRef;
  playBeep() {
    const audio = new Audio('livechat.mp3');
    audio.load();
    audio.play().catch(error => {
      console.error("Error playing audio", error);
    });
  }
  scrollToBottom() {
    this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight;
  }
  fetchActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchActiveOrders(tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {
        this.activeOrder = res.data[0];
        this.fetchChatHistory(this.activeOrder.uid);
        if (res.data.length > 0) {
          var tid = res.data[0].uid;
          var che = `table-notification-${tid}`;
          this.pusherService.channel.bind(che, (data: any) => {
            if (data.type == "chat") {
              this.chatHistory.push(data.data);
              setTimeout(() => this.scrollToBottom(), 100);
              this.playBeep();
            }
          });
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }

  fetchChatHistory(orderId: string) {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchChatHistory(orderId).subscribe({
      next: (res: any) => {
        this.chatHistory = res;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }

  sendChatMessage(orderId: string) {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    if (this.newMessage.trim()) {
      this.chatHistory.push({ body: this.newMessage, sender_type: 'table' });
      setTimeout(() => this.scrollToBottom(), 100);
      let mes = this.newMessage;
      this.newMessage = '';
      this.app.sendChatMessage(orderId, mes).subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          // Optional: Handle any cleanup tasks if needed
        }
      });
    }
  }
}
