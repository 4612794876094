import { Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { HomeComponent } from './home/home.component';
import { BuzzComponent } from './buzz/buzz.component';
import { CartComponent } from './cart/cart.component';
import { CategoryComponent } from './category/category.component';
import { ChatComponent } from './chat/chat.component';
import { OrderConfirmationComponent } from './order/confirmation/confirmation.component';
import { NotificationComponent } from './notification/notification.component';
import { OrderComponent } from './order/order.component';
import { PaymentComponent } from './payment/payment.component';
import { RatingComponent } from './rating/rating.component';
import { SearchComponent } from './search/search.component';
import { PropertiesComponent } from './properties/properties.component';
import { BuzzSuccessComponent } from './buzz/buzz-success/buzz-success.component';
import { ViewComponent } from './notification/view/view.component';
import { OpenedOrderComponent } from './order/opened-order/opened-order.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'buzz',
    component: BuzzComponent
  },
  {
    path: 'buzz-success',
    component: BuzzSuccessComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'category/:id',
    component: CategoryComponent
  },
  {
    path: 'order',
    component: OrderComponent
  },
  {
    path: 'order/confirm',
    component: OrderConfirmationComponent
  },
  {
    path: 'order/opened-order',
    component: OpenedOrderComponent
  },
  {
    path: 'chat',
    component: ChatComponent
  },
  {
    path: 'notifications',
    component: NotificationComponent
  },
  {
    path: 'notification/view',
    component: ViewComponent
  },
  {
    path: 'payment',
    component: PaymentComponent
  },
  {
    path: 'rating',
    component: RatingComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'properties',
    component: PropertiesComponent
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
