<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/home']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>
<div class="buzzing-area">
  <h5>Buzz a waiter</h5>
  <p>
    Why do you need the attention of a waiter?
  </p>
  <div>
    <ul class="list-group">
      <li class="list-group-item" (click)="buzzWaiter('Order Delay')">
        <div class="d-flex align-items-center">
          <img src="./assets/icons/clock.svg" alt="Order Delay">
          <span class="item-text">Order Delay</span>
        </div>
        <img src="./assets/icons/caret-right.svg" alt="Caret right">
      </li>
      <li class="list-group-item" (click)="buzzWaiter('Complaint')">
        <div class="d-flex align-items-center">
          <img src="./assets/icons/complaint.svg" alt="Complaint">
          <span class="item-text">Complaint</span>
        </div>
        <img src="./assets/icons/caret-right.svg" alt="Caret right">
      </li>
      <li class="list-group-item" (click)="buzzWaiter('More Order')">
        <div class="d-flex align-items-center">
          <img src="./assets/icons/order.svg" alt="More Order">
          <span class="item-text">More Order</span>
        </div>
        <img src="./assets/icons/caret-right.svg" alt="Caret right">
      </li>
      <li class="list-group-item" (click)="buzzWaiter('Waiter Attention')">
        <div class="d-flex align-items-center">
          <img src="./assets/icons/attention.svg" alt="Waiter Attention">
          <span class="item-text">Waiter Attention</span>
        </div>
        <img src="./assets/icons/caret-right.svg" alt="Caret right">
      </li>
    </ul>
  </div>
</div>
