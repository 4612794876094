import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AppServiceService } from '../services/app-service.service';
import { EncryptionService } from '../encryption.service';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  showScroll: boolean = false;
  showPopup: boolean = false;
  cartItems: any[] = [];
  deductions: any;
  vat: number = 0;
  vat_type: string = '';
  service_charge: number = 0;
  service_charge_type: string = '';
  private modalService = inject(NgbModal);
  activeOrder: any;
  isConfirming: boolean = false;

  constructor(
    config: NgbModalConfig,
    private router: Router,
    private encryptionService: EncryptionService,
    private app: AppServiceService
  ) {
    config.centered = true;
  }

  ngOnInit(): void {
    this.loadCartItems();
    this.fetchActiveOrders();
    this.fetchDeductions();
    this.deductions = JSON.parse(localStorage.getItem('deductions') || '{}');
  }

  loadCartItems() {
    const cart = localStorage.getItem('cart');
    if (cart) {
      this.cartItems = JSON.parse(cart);
    } else {
      this.cartItems = [];
    }
  }

  // Get subtotal (sum of all items * quantities)
  getTotalAmount(): number {
    return this.cartItems.reduce((total, item) => {
      return total + (item.selling_price * item.quantity);
    }, 0);
  }

  // Calculate VAT amount
  calculateVAT(): number {
    const subtotal = this.getTotalAmount();

    if (!this.deductions || !this.deductions.vat_type) {
      return 0;
    }

    if (this.deductions.vat_type === 'percent') {
      return (Number(this.deductions.vat) / 100) * subtotal;
    } else if (this.deductions.vat_type === 'fixed') {
      return Number(this.deductions.vat);
    }

    return 0;
  }

  // Calculate service charge amount
  calculateServiceCharge(): number {
    const subtotal = this.getTotalAmount();

    if (!this.deductions || !this.deductions.service_charge_type) {
      return 0;
    }

    if (this.deductions.service_charge_type === 'percent') {
      return (Number(this.deductions.service_charge) / 100) * subtotal;
    } else if (this.deductions.service_charge_type === 'fixed') {
      return Number(this.deductions.service_charge);
    }

    return 0;
  }

  // Add new method for withholding tax
  calculateWithholdingTax(): number {
    const subtotal = this.getTotalAmount();

    if (!this.deductions || !this.deductions.withholding_tax_type) {
      return 0;
    }

    if (this.deductions.withholding_tax_type === 'percent') {
      return (Number(this.deductions.withholding_tax) / 100) * subtotal;
    } else if (this.deductions.withholding_tax_type === 'fixed') {
      return Number(this.deductions.withholding_tax);
    }

    return 0;
  }

  // Calculate final total including all charges
  sumTotal(): number {
    const subtotal = this.getTotalAmount();
    const vatAmount = this.calculateVAT();
    const serviceChargeAmount = this.calculateServiceCharge();
    const withholdingTaxAmount = this.calculateWithholdingTax();

    // Use Number() to ensure proper decimal arithmetic
    return Number((
        subtotal +
        (isNaN(vatAmount) ? 0 : vatAmount) +
        (isNaN(serviceChargeAmount) ? 0 : serviceChargeAmount) +
        (isNaN(withholdingTaxAmount) ? 0 : withholdingTaxAmount)
    ).toFixed(2));
  }

  increment(item: any) {
    item.quantity += 1;
    this.updateCart();
  }

  decrement(item: any) {
    if (item.quantity > 1) {
      item.quantity -= 1;
      this.updateCart();
    }
  }

  updateCart() {
    localStorage.setItem('cart', JSON.stringify(this.cartItems));
  }

  removeItem(item: any) {
    this.cartItems = this.cartItems.filter(cartItem => cartItem.id !== item.id);
    this.updateCart();
    this.app.emitCartUpdate();
    this.showPopup = false;
  }

  fetchDeductions() {
    let tableNumber = localStorage.getItem('table');
    if (!tableNumber || tableNumber === 'null') {
      return;
    }

    this.app.fetchDeductions().subscribe({
      next: (res: any) => {
        this.deductions = res;
        localStorage.setItem('deductions', JSON.stringify(res));
      },
      error: (err: any) => {
        console.error('Error fetching deductions:', err);
      }
    });
  }

  fetchActiveOrders() {
    const tableNumber = localStorage.getItem('table');
    if (!tableNumber || tableNumber === 'null') {
      return;
    }

    this.app.fetchActiveOrders(this.encryptionService.decrypt(tableNumber)).subscribe({
      next: (res: any) => {
        this.activeOrder = res.data[0];
      },
      error: (err: any) => {
        console.error('Error fetching active orders:', err);
      }
    });
  }

  confirmOrder() {
    // Set loading state
    this.isConfirming = true;

    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    const order_items = cart.map((item: any) => ({
      inventory_id: item.id,
      quantity: item.quantity
    }));

    const tableNumber = localStorage.getItem('table');
    const payload = {
      tab_open: false,
      ordered_by: "customer",
      barcode_id: tableNumber ? this.encryptionService.decrypt(tableNumber) : '',
      order_items: order_items
    };

    if (this.activeOrder?.uid) {
      this.app.updateOrder(payload, this.activeOrder.uid).subscribe({
        next: (res: any) => {
          this.isConfirming = false; // Reset loading state
          localStorage.removeItem('cart');
          Swal.fire('Confirmed', 'Your order is confirmed and sent to a waiter', 'success');
          location.href = '/home';
        },
        error: (err) => {
          this.isConfirming = false; // Reset loading state
          Swal.fire('Error!', err.error.message, 'error');
        }
      });
      return;
    }

    this.app.createOrder(payload).subscribe({
      next: (res: any) => {
        this.isConfirming = false; // Reset loading state
        localStorage.removeItem('cart');
        localStorage.setItem('secretKey', res.secret_key);
        Swal.fire('Confirmed', 'Your order is confirmed and sent to a waiter', 'success');
        location.href = '/home';
      },
      error: (err) => {
        this.isConfirming = false; // Reset loading state
        Swal.fire('Error!', err.error.message, 'error');
      }
    });
  }

  open(content: any) {
    this.modalService.open(content);
  }

  closePopup() {
    this.showPopup = false;
  }

  onCardScroll(card: HTMLElement) {
    this.showScroll = card.scrollTop > 100;
  }

  scrollToTop(card: HTMLElement) {
    card.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
