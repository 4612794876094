import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NavbarComponent } from '../layout/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { AppServiceService } from '../services/app-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms'; // Add this import
import { EncryptionService } from '../encryption.service';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [
    RouterModule,
    NavbarComponent,
    CommonModule,
    FormsModule // Add this
  ],
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent {
  total = 0;
  items: any[] = [];
  page = 1;
  loading = false;
  totalPages: number = 0;
  id: any = '';
  category: any;
  noTable: boolean = false;
  tempItem: any;

  // Add new search properties
  searchTerm: string = '';
  filteredItems: any[] = [];

  constructor(
    private router: Router,
    private app: AppServiceService,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private toastr: ToastrService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      this.noTable = true;
    }
    this.fetchAllCategoryItems();
    this.fetchCategoryDetails();
    this.filteredItems = this.items; // Initialize filtered items
  }

  // Updated fetchAllCategoryItems with search support
  fetchAllCategoryItems() {
    if (this.loading) return;
    this.loading = true;

    let cart = JSON.parse(localStorage.getItem('cart') || '[]');
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchAllCategoryItems(this.page, this.id, tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {
        const newItems = res.data.map((item: any) => {
          const cartItem = cart.find((cartItem: any) => cartItem.id === item.id);
          const inCart = !!cartItem;
          const userQuantity = cartItem ? cartItem.quantity : 0;
          return { ...item, userQuantity, inCart };
        });
        this.items = [...this.items, ...newItems];
        this.filteredItems = this.filterItems(this.searchTerm); // Update filtered items
        this.totalPages = res.meta.last_page;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  // Add search methods
  onSearch(event: any): void {
    const term = event.target.value.toLowerCase();
    this.searchTerm = term;
    this.filteredItems = this.filterItems(term);
  }

  private filterItems(term: string): any[] {
    if (!term) return this.items;

    return this.items.filter(item =>
      item.name.toLowerCase().includes(term.toLowerCase()) ||
      (item.category && item.category.name.toLowerCase().includes(term.toLowerCase()))
    );
  }

  fetchCategoryDetails() {
    this.loading = true;
    this.app.fetchCategoryDetails(this.id).subscribe({
      next: (res: any) => {
        this.category = res;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  capitalizeFirstLetter(word: string) {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  increment(item: any) {
    if (item.userQuantity == 0) {
      item.userQuantity = 1;
      this.addToCart(item);
      return;
    }
    item.userQuantity += 1;

    let cart = JSON.parse(localStorage.getItem('cart') || '[]');
    let cartItem = cart.find((cartItem: any) => cartItem.id === item.id);

    if (cartItem) {
      cartItem.quantity = item.userQuantity;
    }

    localStorage.setItem('cart', JSON.stringify(cart));

    this.toastr.success(`${item.name} quantity has been increased`, 'Quantity Updated', {
      timeOut: 2000
    });
  }

  decrement(item: any) {
    if (item.userQuantity > 1) {
      item.userQuantity -= 1;

      let cart = JSON.parse(localStorage.getItem('cart') || '[]');
      let cartItem = cart.find((cartItem: any) => cartItem.id === item.id);

      if (cartItem) {
        cartItem.quantity = item.userQuantity;
      }

      localStorage.setItem('cart', JSON.stringify(cart));

      this.toastr.success(`${item.name} quantity has been decreased`, 'Quantity Updated', {
        timeOut: 2000
      });
    }
  }

  goToCart() {
    this.modal.dismissAll();
    this.router.navigateByUrl('/cart');
  }

  addToCartModal(item: any, content: any) {
    this.tempItem = item;
    this.modal.open(content, { centered: true, size: 'md' })
  }

  addToCart(item: any) {
    if (item.userQuantity === 0 || item.inCart) {
      return;
    }

    let cart = JSON.parse(localStorage.getItem('cart') || '[]');

    cart.push({
      id: item.id,
      name: item.name,
      quantity: item.userQuantity,
      selling_price: item.selling_price,
      main_image: item.main_image,
    });

    localStorage.setItem('cart', JSON.stringify(cart));

    item.inCart = true;
    this.app.emitCartUpdate();

    this.toastr.success(`${item.name} has been added to your cart`, 'Item Added', {
      timeOut: 2000
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100 && !this.loading) {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchAllCategoryItems();
      }
    }
  }
}
