<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/cart']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>

<div class="order-area mb-3">
  <h5>Opened Order</h5>
</div>
<div *ngIf="activeOrders && activeOrders.length > 0">
  <div class="order-card my-3" *ngFor="let order of activeOrders">
    <!-- Order Items with Individual Prices -->
    <div class="order-items-section">
      <p class="order-item" *ngFor="let item of order.order_items">
        {{item.inventory}} (₦{{item.amount / item.quantity}}) X{{item.quantity}}
        <span class="item-price">{{ item.amount * item.quantity | currency: '₦'}}</span>
      </p>
    </div>

    <!-- Deductions Section -->
    <div class="deductions-section mt-3" *ngIf="order.deductions">
      <p class="deduction-title">Charges & Taxes</p>
      <div class="deduction-item" *ngIf="getDeductions(order).service_charge_value">
        <span>Service Charge ({{getDeductions(order).service_charge_value}}%)</span>
        <span>{{ (order.amount * (getDeductions(order).service_charge_value/100)) | currency: '₦'}}</span>
      </div>
      <div class="deduction-item" *ngIf="getDeductions(order).withholding_tax_value">
        <span>WHT ({{getDeductions(order).withholding_tax_value}}%)</span>
        <span>{{ (order.amount * (getDeductions(order).withholding_tax_value/100)) | currency: '₦'}}</span>
      </div>
      <div class="deduction-item" *ngIf="getDeductions(order).vat_amount">
        <span>VAT</span>
        <span>{{ getDeductions(order).vat_amount | currency: '₦'}}</span>
      </div>
    </div>

    <!-- Total Amount -->
    <div class="total-section mt-3">
      <span class="order-amount-title">Total Amount</span>
      <p class="order-amount mb-3">{{ order.total_amount | currency: '₦'}}</p>
    </div>

    <span class="order-amount-title">Order Number</span>
    <p class="order-id mb-5">{{ order.uid }}</p>

    <div class="row">
      <div class="col-4">
        <p class="status-name" [ngClass]="{'active': order.orderStatus >= 1}">Accepted</p>
      </div>
      <div class="col-4">
        <p class="status-name text-center" [ngClass]="{'active': order.orderStatus >= 2}">Delivered</p>
      </div>
      <div class="col-4">
        <p class="status-name text-end" [ngClass]="{'active': order.orderStatus >= 3}">Completed</p>
      </div>
    </div>
    <div class="status-tracker mb-4">
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 1}">
        <div class="circle"></div>
      </div>
      <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 2}"></div>
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 2}">
        <div class="circle"></div>
      </div>
      <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 3}"></div>
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 3}">
        <div class="circle"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <a [routerLink]="['/buzz']">
          <button class="btn btn-primary btn-block btn-danger btn-block bt-buzz">Buzz a Waiter <img
              src="./assets/icons/buzz-strike.svg" alt=""></button></a>
      </div>
      <div class="col-6">
        <a [routerLink]="['/']">
          <button class="btn btn-primary btn-block btn-block">Add More Items</button>
        </a>
      </div>
    </div>
  </div>

</div>
