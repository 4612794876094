<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/home']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>
<div class="cart-area mb-3">
  <h5>Cart</h5>
</div>

<!-- Cart is empty -->
<main *ngIf="cartItems.length === 0">
  <div class="centered-div">
    <div class="">
      <img src="./assets/icons/cook.svg" alt="">
      <p class="mt-5">
        Your cart is currently empty. Please make an order.
      </p>
      <div>
        <a [routerLink]="['/home']">
          <button class="btn btn-primary btn-block">Explore other food categories</button>
        </a>
      </div>
    </div>
  </div>
</main>

<!-- Cart has items -->
<content #scrollableCard (scroll)="onCardScroll(scrollableCard)" *ngIf="cartItems.length > 0">
  <div *ngFor="let item of cartItems" class="card border rounded-2 position-relative mb-3">
    <div class="card-body">
      <div class="position-absolute end-0 top-0">
        <button class="btn btn-clear btn-sm btn-cancel" (click)="removeItem(item)">
          <img src="./assets/icons/x.svg" alt="X">
        </button>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="">
            <img [src]="item.main_image" width="91" height="91" [alt]="item.name" class="rounded img-fluid">
          </div>
        </div>
        <div class="col-8">
          <h6 class="mb-0 text-break me-2">{{ item.name }}</h6>
          <p class="card-text">{{ item.selling_price | currency: '₦' }} x <span class="text-muted">{{ item.quantity }}
              Units</span></p>
          <div class="d-flex align-items-end justify-content-between">
            <h6 class="amount">{{ item.selling_price * item.quantity | currency: '₦'}}</h6>
            <div class="ms-auto border rounded-5">
              <div class="quantity-control d-flex justify-content-between align-items-center me-1">
                <button class="btn btn-clear btn-sm" (click)="decrement(item)">−</button>
                <span class="quantity">{{ item.quantity }}</span>
                <button class="btn btn-sm btn-clear" (click)="increment(item)">+</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</content>

<!-- Total Amount -->
<footer *ngIf="cartItems.length > 0">
  <div class="row mt-3">
    <div class="col-6">
      <h5 class="total">Subtotal</h5>
    </div>
    <div class="col-6 text-end">
      <h5 class="amount"> {{ getTotalAmount() |
        currency: '₦'}}</h5>
    </div>
  </div>
  <div class="row mt-2" *ngIf="deductions.vat_type">
    <div class="col-6">
      <h5 class="total">VAT({{deductions.vat+"%"}})</h5>
    </div>
    <div class="col-6 text-end">
      <h5 class="amount" *ngIf="deductions.vat_type == 'percent'"> {{ (deductions.vat/100 * getTotalAmount()) |
        currency: '₦'}}</h5>
      <h5 class="amount" *ngIf="deductions.vat_type == 'fixed'"> {{ deductions.vat |
        currency: '₦'}}</h5>
    </div>
  </div>
  <div class="row mt-2" *ngIf="deductions.service_charge_type">
    <div class="col-6">
      <h5 class="total" *ngIf="deductions.service_charge_type == 'percent'">Service
        Charge({{deductions.service_charge+"%"}})</h5>
      <h5 class="total" *ngIf="deductions.service_charge_type == 'fixed'">Service Charge</h5>
    </div>
    <div class="col-6 text-end">
      <h5 class="amount" *ngIf="deductions.service_charge_type == 'percent'"> {{ (deductions.service_charge/100 *
        getTotalAmount()) |
        currency: '₦'}}</h5>
      <h5 class="amount" *ngIf="deductions.service_charge_type == 'fixed'"> {{ deductions.service_charge |
        currency: '₦'}}</h5>
    </div>
  </div>
  <div class="row mt-2" *ngIf="deductions.withholding_tax_type">
    <div class="col-6">
      <h5 class="total">Withholding Tax({{deductions.withholding_tax+"%"}})</h5>
    </div>
    <div class="col-6 text-end">
      <h5 class="amount" *ngIf="deductions.withholding_tax_type == 'percent'">
        {{ (deductions.withholding_tax/100 * getTotalAmount()) | currency: '₦'}}
      </h5>
      <h5 class="amount" *ngIf="deductions.withholding_tax_type == 'fixed'">
        {{ deductions.withholding_tax | currency: '₦'}}
      </h5>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <h5 class="total2">Total</h5>
    </div>
    <div class="col-6 text-end">
      <h5 class="amount2"> {{ sumTotal() | currency: '₦'}}</h5>
    </div>
  </div>
  <div class="text-center mt-3">
      <button class="btn btn-primary btn-block" [disabled]="isConfirming" (click)="confirmOrder()">
          <span *ngIf="isConfirming" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          {{isConfirming ? 'Confirming Order...' : 'Confirm Order'}}
      </button>
  </div>
</footer>
