import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppServiceService } from '../services/app-service.service';
import { PusherService } from '../services/pusher.service';
import { EncryptionService } from '../encryption.service';
import { formatDistanceToNow } from 'date-fns';
@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  total = 0;
  items: any[] = []; // Store fetched items
  page = 1; // Start from page 1
  loading = false; // Flag to handle loading state
  totalPages: number = 0; // Will store total pages from API response
  activeOrder: any;
  constructor(
    private router: Router,
    private service: AppServiceService,
    private pusherService: PusherService,
    private encryptionService: EncryptionService
  ) { }
  ngOnInit(): void {
    this.fetchActiveOrders();
  }
  fetchActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.service.fetchActiveOrders(tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {
        this.activeOrder = res.data[0];
        this.fetchAllNotifications(this.activeOrder.uid);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }
  fetchAllNotifications(orderId: string) {
    if (this.loading) return; // Prevent multiple calls
    this.loading = true;

    this.service.fetchAllNotifications(orderId, this.page).subscribe({
      next: (res: any) => {
        this.items = res.data;
        this.totalPages = res.last_page;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
  getTimeAgo(dateString: string): string {
    try {
      const date = new Date(dateString);
      const timeAgo = formatDistanceToNow(date, { addSuffix: true });

      // Customize the output for better readability
      return timeAgo
        .replace('about ', '')
        .replace('less than a minute ago', 'just now')
        .replace('minute ago', 'min ago')
        .replace('minutes ago', 'mins ago')
        .replace('hour ago', 'hr ago')
        .replace('hours ago', 'hrs ago')
        .replace('day ago', 'd ago')
        .replace('days ago', 'd ago')
        .replace('month ago', 'mo ago')
        .replace('months ago', 'mo ago')
        .replace('year ago', 'y ago')
        .replace('years ago', 'y ago');
    } catch (error) {
      return 'just now';
    }
  }

  // Optional: Add a method to refresh times periodically
  startTimeRefresh() {
    setInterval(() => {
      this.items = this.items.map(notification => ({
        ...notification,
        timeAgo: this.getTimeAgo(notification.created_at)
      }));
    }, 60000); // Update every minute
  }
}
