<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/cart']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>

<div class="order-area mb-3">
  <h5>Confirm Order</h5>
</div>

<div class="content-wrapper pt-3">
  <div class="form-group">
    <label for="" class="form-label">Name <span>(Optional)</span></label>
    <input type="text" class="search-box" placeholder="Name" [(ngModel)]="name">
  </div>

  <div class="form-group">
    <label for="" class="form-label">Email <span>(Optional)</span></label>
    <input type="email" class="search-box" placeholder="Email address" [(ngModel)]="email">
  </div>

  <div class="form-group">
    <label for="" class="form-label">Phone Number <span>(Optional)</span></label>
    <input type="tel" class="search-box" placeholder="Phone Number" [(ngModel)]="phoneNumber">
  </div>

  <div class="button-wrapper">
    <button class="btn btn-primary btn-block mt-4" (click)="confirmOrder()">Confirm Order</button>
    <button class="btn btn-primary btn-block mt-3 skip" (click)="confirmOrder()">Skip</button>
  </div>
</div>
