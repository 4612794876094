/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { provideAnimations } from '@angular/platform-browser/animations'; // Import animations
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule
import { importProvidersFrom } from '@angular/core'; // Needed for provider configuration
import { AppComponent } from './app/app.component';

// Modify appConfig to include ToastrModule and BrowserAnimationsModule
bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...appConfig.providers,
    // Provide animations
    provideAnimations(),

    // Provide ToastrModule globally
    importProvidersFrom(
      ToastrModule.forRoot({
        timeOut: 3000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      })
    )
  ]
}).catch((err) => console.error(err));
