import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'; // Import FormsModule for ngModel binding
import { HttpClient } from '@angular/common/http'; // Import HttpClient to send request
import { AppServiceService } from '../services/app-service.service';
import { EncryptionService } from '../encryption.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  name: string = '';
  email: string = '';
  phoneNumber: string = '';

  constructor(private router: Router, private encryptionService: EncryptionService, private http: HttpClient, private app: AppServiceService) { }

  ngOnInit(): void {
    // Check if userData exists in localStorage and pre-fill the form if it does
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      const userData = JSON.parse(savedUserData);
      this.name = userData.name || '';        // Pre-fill with saved name or leave empty
      this.email = userData.email || '';      // Pre-fill with saved email or leave empty
      this.phoneNumber = userData.phoneNumber || '';  // Pre-fill with saved phone number or leave empty
    }
  }

  confirmOrder() {
    const userData = {
      name: this.name.trim(),
      email: this.email.trim(),
      phoneNumber: this.phoneNumber.trim(),
    };

    // Save user data to localStorage if any field is filled
    if (userData.name || userData.email || userData.phoneNumber) {
      localStorage.setItem('userData', JSON.stringify(userData));
    }

    // Get cart items from localStorage
    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    const order_items = cart.map((item: any) => {
      return {
        inventory_id: item.id,
        quantity: item.quantity
      };
    });
    let tableNumber = localStorage.getItem('table');
    // Construct the payload
    const payload: any = {
      tab_open: false,
      ordered_by: "customer",
      barcode_id: tableNumber ? this.encryptionService.decrypt(tableNumber) : '',
      order_items: order_items
    };
    if (userData.name) payload.customer_name = userData.name;
    if (userData.email) payload.customer_email = userData.email;
    if (userData.phoneNumber) payload.customer_phone = userData.phoneNumber
    // Send payload to backend (example endpoint)
    this.app.createOrder(payload).subscribe({
      next: (res: any) => {
        localStorage.removeItem('cart');
        Swal.fire('Confirmed', 'Your order is confirmed and sent to a waiter', 'success')
        this.router.navigateByUrl('/home');
      },
      error: (err) => {
        Swal.fire('Yo!', err.error.message, 'error')
      },
      complete: () => {
      }
    });
  }

}
