<button class="btn btn-primary mb-3">Primary button</button>
<br>
<button class="btn btn-secondary mb-3">Secondary button</button>
<br>
<a href="" class="mb-3"> Test link</a>
<br>
<button class="btn btn-danger mb-3 ">danger button</button>
<br>
<div class="text-center mb-3">
  <button class="btn btn-primary btn-block ">block button</button>
</div>
<div class="text-center mb-3">
  <button class="btn btn-primary btn-half_block ">Half block button</button>
</div>
<div class="card card-danger mb-3">
  <div class="card-body">
    <p>danger card</p>
  </div>
</div>
<div class="card card-success mb-3">
  <div class="card-body">
    <p>success card</p>
  </div>
</div>
<div class="card card-warning mb-3">
  <div class="card-body">
    <p>warning card</p>
  </div>
</div>

<div class="card card-dark mb-3">
  <div class="card-body">
    <p>dark card</p>
  </div>
</div>
<span>
  <i class="fa-light fa-pot-food fa-2xl text-danger"></i>
  <i class="fa-regular fa-burger-lettuce fa-2xl text-warning"></i>
  <i class="fa-light fa-cup-straw-swoosh fa-flip-horizontal fa-2xl text-success"></i>
  <i class="fa-sharp fa-light fa-baguette fa-2xl text-dark"></i>
  <i class="fa-light fa-fish fa-rotate-by fa-2xl text-danger" style="--fa-rotate-angle: 315deg;"></i>
  <i class="fa-light fa-drumstick fa-2xl text-warning"></i>
</span>
