import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-buzz-success',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './buzz-success.component.html',
  styleUrl: './buzz-success.component.scss'
})
export class BuzzSuccessComponent {

}
