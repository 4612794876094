<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/notifications']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>
<div class="notification-area">
  <h5 class="mb-4">Order Confirmation</h5>
  <div>
    <p>
      Your order has been confirmed by Tolulope. Your
      order will be ready in 5 minutes. For concerns or
      more instructions. Please use the chat waiter to
      communicate with Tolulope.
    </p>

  </div>
</div>
<div class="bottom text-center">
  <a [routerLink]="['/chat']">
  <button class="btn btn-primary btn-block">Chat Waiter</button>
  </a>
</div>
