<div [ngClass]="{'container': !isBuzzRoute}">
  <div class="popup-overlay" *ngIf="showPopup">
    <div class="popup-content">
      <p class="message">{{ message }}</p>
      <div class="button-group">
        <button class="btn btn-done" (click)="closePopup()">Ok</button>
        <button class="btn btn-chat" (click)="closePopup();goToChat()">Chat Waiter</button>
      </div>
    </div>
  </div>
  <app-spinner></app-spinner>
  <router-outlet></router-outlet>
</div>
