<app-navbar></app-navbar>
<div class="mb-3 mt-3 category-name">
  <h5>{{category && capitalizeFirstLetter(category.name)}}</h5>
</div>

<!-- Search Bar -->
<div class="input-group my-1 mb-4">
  <div class="search-boxe">
    <input
      type="text"
      [(ngModel)]="searchTerm"
      (input)="onSearch($event)"
      placeholder="Search in {{category?.name}}"
      class="form-control"
    />
    <button>
      <img src="./assets/icons/search.svg" alt="">
    </button>
  </div>
</div>

<!-- Unavailable data -->
<div class="text-center" *ngIf="filteredItems.length === 0">
  <img src="./assets/icons/cook.svg" alt="">
  <p class="mt-5">
    <ng-container *ngIf="searchTerm; else noItems">
      No results found for "{{searchTerm}}" in {{category?.name}}
    </ng-container>
    <ng-template #noItems>
      Sorry, your search is not on our {{category && category.name ?? ''}} menu right now. Please explore other options or browse
      other categories
    </ng-template>
  </p>

  <div class="mb-3">
    <a [routerLink]="['/home']">
      <button class="btn btn-primary btn-block">Explore other food categories</button>
    </a>
  </div>
</div>

<div>
  <div class="row my-4">
    <div *ngFor="let item of filteredItems" class="col-md-4 col-6 mb-4">
      <img [src]="item.main_image" class="product-image" [alt]="item.name">

      <div class="mt-2">
        <h6 class="card-title mb-0">{{ item.name }}</h6>
        <p class="card-text">{{ item.selling_price | currency: '₦' }} - <span class="text-muted">per unit</span></p>
      </div>
      <div class="mt-2">
        <!-- If item is in cart, show increment/decrement buttons -->
        <ng-container *ngIf="item.inCart; else addToCartButton">
          <div class="row">
            <div class="col-4">
              <button class="btn btn-clear btn-quant btn-xs" [disabled]="item.userQuantity <= 1"
                (click)="decrement(item)">−</button>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center justify-content-center pt-1">
                <p class="quantity">{{ item.userQuantity }}</p>
              </div>
            </div>
            <div class="col-4">
              <button class="btn btn-xs btn-quant btn-clear" (click)="increment(item)">+</button>
            </div>
          </div>
        </ng-container>

        <!-- "Add to Cart" button if item is not in cart -->
        <ng-template #addToCartButton>
          <button class="btn btn-primary btn-block ms-auto" [disabled]="noTable"
            (click)="addToCartModal(item,content)">Add To Cart</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template let-modal #content>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <h6 class="variation">Please select a variation</h6>
      </div>
      <div class="col-6">
        <div class="float-right">
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <div class="product-item mt-4">
      <div class="row">
        <div class="col-8">
          <h6 class="card-title mb-0">{{ tempItem.name }}</h6>
          <p class="card-text">{{ tempItem.selling_price | currency: '₦'}} - <span class="text-muted">per unit</span>
          </p>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-4">
              <button class="btn btn-clear btn-quan btn-xs" [disabled]="tempItem.userQuantity <= 1"
                (click)="decrement(tempItem)">−</button>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center justify-content-center pt-1">
                <p class="quantity">{{ tempItem.userQuantity }}</p>
              </div>
            </div>
            <div class="col-4">
              <button class="btn btn-xs btn-quan btn-clear" (click)="increment(tempItem)">+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-primary btn-lg btn-block continue-shopping mb-3 mb-md-0"
            (click)="modal.dismiss()">Continue Shopping</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-primary btn-lg btn-block" (click)="goToCart()">View Cart And Checkout</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
