<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/home']">
    <img src="./assets/icons/back.svg" alt="Back" class="back">
  </a>
</div>
<div class="notification-area mt-3">
  <h5 class="mb-3">Notifications</h5>
  <div>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let notification of items">
        <!-- <a [routerLink]="['/notifications/1']"> -->
        <div class="d-flex justify-content-between align-items-center">
          <div class="item-header d-flex align-items-center">
            <span class="item-text">{{notification.data.title}}</span>
          </div>
          <span class="time">{{ getTimeAgo(notification.created_at)}}</span>
        </div>
        <p class="ellipsis">{{notification.data.details}}</p>
        <!-- </a> -->
      </li>
    </ul>

  </div>
  <div class="d-flex align-items-center" *ngIf="items.length === 0">
    <div class="mx-auto">
        <img src="./assets/icons/cook.svg" class="img-fluid" alt="Cook">
      <p class="pt-3">
        There are no notifications now
      </p>
    </div>
  </div>
</div>
