import { Component, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NavbarComponent } from '../layout/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { AppServiceService } from '../services/app-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EncryptionService } from '../encryption.service';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, switchMap, of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PusherService } from '../services/pusher.service';
import { OrderUpdateService } from '../services/order-update.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule, NavbarComponent, CommonModule, NgbModule, FormsModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnDestroy {
  categories: any[] = [];
  tableValue: string | null = null;
  colorClasses = ['bg-light-red', 'bg-light-green', 'bg-light-yellow', 'bg-light-brown'];
  activeOrders: any;
  private orderUpdateSubscription: Subscription;
  // Search related properties
  searchTerm: string = '';
  searchResults: any[] = [];
  isLoading: boolean = false;
  showDropdown: boolean = false;
  private searchSubject = new Subject<string>();

  constructor(
    private app: AppServiceService,
    private encryptionService: EncryptionService,
    private pusherService: PusherService,
    private route: ActivatedRoute,
    private router: Router,
    private orderUpdateService: OrderUpdateService
  ) {
    // Initialize search observer
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        let tableNumber = localStorage.getItem('table');
        if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
          this.showDropdown = false;
          return of([]);
        }
        if (!term.trim()) {
          this.showDropdown = false;
          return of([]);
        }
        this.isLoading = true;
        return this.app.searchInventory(term, tableNumber ? this.encryptionService.decrypt(tableNumber) : '');
      })
    ).subscribe({
      next: (results) => {
        this.searchResults = results;
        this.isLoading = false;
        this.showDropdown = true;
        if (this.searchTerm == '') {
          this.showDropdown = false;
        }
      },
      error: (error) => {
        console.error('Search error:', error);
        this.isLoading = false;
      }
    });
    this.orderUpdateSubscription = this.orderUpdateService.orderUpdate$.subscribe(() => {
      this.fetchActiveOrders();
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tableValue = params['table'] || null;
    });
    if (this.tableValue !== null) {
      localStorage.setItem('table', this.tableValue.toString());
    }
    this.fetchDeductions();
    this.fetchAllCategories();
    this.fetchActiveOrders();
  }

  getRandomColorClass(index: number): string {
    return this.colorClasses[index % this.colorClasses.length];
  }

  fetchAllCategories() {
    this.app.fetchAllCategories().subscribe({
      next: (res: any) => {
        this.categories = res;
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  fetchActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchActiveOrders(tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {
        this.activeOrders = res.data.map((order: any) => {
          let orderStatus = 0; // Default to 0 (ordered)
          if (order.status === 'accepted') {
            orderStatus = 1;
          } else if (order.status === 'delivered') {
            orderStatus = 2;
          } else if (order.status === 'completed') {
            orderStatus = 3;
          }
          return {
            ...order, // Keep all other properties of the order
            orderStatus // Add the new orderStatus key
          };
        });
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }

  fetchDeductions() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchDeductions().subscribe({
      next: (res: any) => {
        localStorage.setItem('deductions', JSON.stringify(res));
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }

  // New search methods
  // onSearch(event: any): void {
  //   const term = event.target.value;
  //   this.searchTerm = term;
  //   // th
  //   this.searchSubject.next(term);
  // }

  selectItem(item: any): void {
    this.searchTerm = item.name;
    this.showDropdown = false;
    if (item && item.category_id) {
      this.router.navigate(['/category', item.category_id]);
    }
    this.searchTerm = '';
    this.showDropdown = false;
    this.searchResults = [];
    // Additional logic for selection...
  }

  // c// Add to your component class
  closeDropdown(): void {
    this.showDropdown = false;
    this.searchResults = [];
  }

  // Update onSearch method
  onSearch(event: any): void {
    const term = event.target.value;
    this.searchTerm = term;

    if (term.trim()) {
      this.showDropdown = true;
      this.searchSubject.next(term);
    } else {
      this.showDropdown = false;
      this.searchResults = [];
    }
  }

  ngOnDestroy() {
    this.searchSubject.complete();
    if (this.orderUpdateSubscription) {
      this.orderUpdateSubscription.unsubscribe();
    }
  }
}
