import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderUpdateService {
  private orderUpdateSubject = new Subject<void>();
  orderUpdate$ = this.orderUpdateSubject.asObservable();

  notifyOrderUpdate() {
    this.orderUpdateSubject.next();
  }
}
