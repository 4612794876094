<div class="d-flex align-items-center justify-content-center vh-100">
  <div class="text-center">
    <h1>A waiter will be with you shortly</h1>

    <div class="checkmark-container">
      <span class="checkmark">
        <span class="fa fa-check"></span>
      </span>
    </div>
    <a [routerLink]="['/home']">

    <button class="btn done-button">Done</button>
    </a>
  </div>
</div>
