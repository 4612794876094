<div class="chat-container pt-3">
  <!-- Back Button -->
  <div class="header">
    <button class="back-button mb-3">
      <a [routerLink]="['/home']">
      <img src="./assets/icons/back.svg" alt="Back">
      </a>
    </button>
    <!-- Profile Section -->
    <div class="profile text-center">
      <img [src]="activeOrder?.waiter?.image ?? './assets/images/waiter.png'" alt="User" class="profile-pic">
      <p class="username pt-2">{{ activeOrder?.waiter?.first_name }}</p>
    </div>
  </div>

  <!-- Chat Body -->
  <div class="chat-body" #chatBody>
    <div *ngFor="let message of chatHistory" [ngClass]="{'sent': message.sender_type == 'table', 'received': message.sender_type == 'waiter'}">
      <div class="message">
        {{ message.body }}
      </div>
    </div>
  </div>

  <!-- Chat Input -->
  <div class="chat-input-container">
    <input type="text" placeholder="Type a message" [(ngModel)]="newMessage" (keyup.enter)="sendChatMessage(activeOrder?.uid)" class="chat-input">
    <button class="send-button" (click)="sendChatMessage(activeOrder?.uid)">
      <img src="./assets/icons/send-message.svg"  alt="Send">
    </button>
  </div>
</div>
