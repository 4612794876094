import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
// import { ToastService } from 'angular-toastify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

const secretKey = localStorage.getItem('secretKey');
let headers = new HttpHeaders({
  'Content-Type': 'application/json',
});
if (secretKey) {
  headers = headers.set('Secret-key', secretKey);
}
const httpOptions = {
  headers: headers,
};

@Injectable({
  providedIn: 'root',
})

export class AppServiceService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modal: NgbModal,
  ) {}

  cartUpdated: EventEmitter<void> = new EventEmitter<void>();

  // This method is used to trigger the cart update
  emitCartUpdate() {
    this.cartUpdated.emit();
  }

  fetchAllCategories() {
    return this.http.get(`${environment.base_api}/inventories/categories`, httpOptions).pipe();
  }

  fetchCategoryDetails(id: any) {
    return this.http.get(`${environment.base_api}/inventories/show-category/${id}`, httpOptions).pipe();
  }

  fetchAllCategoryItems(page: number, category_id: any, table_id: any) {
    return this.http.get(`${environment.base_api}/inventories?page=${page}&category=${category_id}&table_id=${table_id}`, httpOptions).pipe();
  }

  fetchAllNotifications(order_id: any, page: number) {
    return this.http.get(`${environment.base_api}/barcode-management/notifications/${order_id}?page=${page}`, httpOptions).pipe();
  }

  createOrder(payload: any) {
    return this.http.post(`${environment.base_api}/orders/store`, payload, httpOptions).pipe();
  }
  updateOrder(payload: any, order_id: any) {
    return this.http.patch(`${environment.base_api}/orders/add-item/${order_id}`, payload, httpOptions).pipe();
  }

  fetchActiveOrders(barcode_id: any) {
    return this.http.get(`${environment.base_api}/orders/active-orders?barcode_id=${barcode_id}`, httpOptions).pipe();
  }
  searchInventory(term: string, table_id: any, limit: number = 10): Observable<any> {
    const params = new HttpParams()
      .set('q', term)
      .set('limit', limit.toString())
      .set('table_id', table_id);
    return this.http.get<any>(`${environment.base_api}/inventories/search`, { params })
      .pipe(
        map(response => response.data),
        catchError(error => {
          console.error('Search error:', error);
          return of([]);
        })
      );
  }
  fetchDeductions() {
    return this.http.get(`${environment.base_api}/orders/deductions`, httpOptions).pipe();
  }

  fetchChatHistory(order_id: any) {
    let payload = {
      order_id: order_id
    };
    return this.http.post(`${environment.base_api}/orders/chat-history`, payload, httpOptions).pipe();
  }

  sendChatMessage(order_id: any, message: any) {
    let payload = {
      order_id: order_id,
      message: message
    };
    return this.http.post(`${environment.base_api}/orders/chat-waiter`, payload, httpOptions).pipe();
  }

  buzzWaiter(payload: any) {
    return this.http.post(`${environment.base_api}/buzz-waiter`, payload, httpOptions).pipe();
  }
}
