import { Component, NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { PusherService } from './services/pusher.service';
import { EncryptionService } from './encryption.service';
import { AppServiceService } from './services/app-service.service';
import { OrderUpdateService } from './services/order-update.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, SpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'dotalink';
  isBuzzRoute = false;
  showPopup: boolean = false;
  message: string = '';
  constructor(private router: Router, private app: AppServiceService, private orderUpdateService: OrderUpdateService, private zone: NgZone, private pusherService: PusherService, private encryptionService: EncryptionService) { }

  ngOnInit(): void {
    this.fetchDeductions();
    this.fetchActiveOrders();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isBuzzRoute = event.url === '/buzz-success';
      });
  }
  fetchDeductions() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchDeductions().subscribe({
      next: (res: any) => {
        localStorage.setItem('deductions', JSON.stringify(res));
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }
  goToChat() {
    this.router.navigateByUrl('/chat');
  }
  playChatBeep() {
    const audio = new Audio('livechat.mp3');
    audio.load();
    audio.play().catch(error => {
      console.error("Error playing audio", error);
    });
  }
  closePopup(): void {
    this.showPopup = false;
  }
  fetchActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    if (tableNumber == null || tableNumber == '' || tableNumber == 'null') {
      return;
    }
    this.app.fetchActiveOrders(tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {

        if (res.data.length > 0) {
          var tid = res.data[0].uid;
          var che = `table-notification-${tid}`;
          console.log(che);
          this.pusherService.channel.bind(che, (data: any) => {
            console.log(data);
            this.playChatBeep();
            if (data.type == "order_claimed") {
              this.zone.run(() => {
                this.showPopup = true; // This will ensure Angular detects the change
                this.message = data.details;
              });
            } else if (data.type == "chat") {
              if (this.router.url !== '/chat') { // Replace '/chat' with your actual chat route
                this.zone.run(() => {
                  this.showPopup = true;
                  this.message = `You just received a message from ${data.data.sender.first_name}.`;
                });
              }
            } else if (data.type == "order_wait_time") {
              this.zone.run(() => {
                this.showPopup = true; // This will ensure Angular detects the change
                this.message = data.message;
              });
            }
            else if (data.type == "order_delivered") {
              this.zone.run(() => {
                this.showPopup = true; // This will ensure Angular detects the change
                this.message = data.details;
              });
            } else if (data.type == "order_completed") {
              this.zone.run(() => {
                this.showPopup = true; // This will ensure Angular detects the change
                this.message = data.details;
                this.orderUpdateService.notifyOrderUpdate();
              });
            }

          });
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }
}
