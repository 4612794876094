import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AppServiceService } from '../services/app-service.service';
import { EncryptionService } from '../encryption.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-buzz',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './buzz.component.html',
  styleUrl: './buzz.component.scss'
})
export class BuzzComponent {
  constructor(private router: Router, private app: AppServiceService, private encryptionService: EncryptionService) { }

  buzzWaiter(reason: string) {
    var tableNumber = localStorage.getItem('table');
    let payload = {
      table: tableNumber ? this.encryptionService.decrypt(tableNumber) : '',
      reason_for_buzz: reason
    };
    this.app.buzzWaiter(payload).subscribe({
      next: (res: any) => {
        Swal.fire('Confirmed', 'A waiter will be with you shortly', 'success')
        this.router.navigateByUrl('/home');
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
      }
    });
  }
}
