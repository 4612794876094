<div class="d-flex justify-content-between align-items-center py-3">
  <a [routerLink]="['/home']"><i class="fa-light fa-arrow-left fa-xl"></i></a>
  <div class="ms-auto">
    <button class="btn btn-danger btm-sm btn-buzz me-3">Buzz a Waiter <i class="fas fa-bolt"></i></button>
    <i class="fa-light fa-cart-shopping fa-xl"></i>
  </div>
</div>

<!-- Search Bar -->
<div class="input-group my-3">
  <div class="search-box">
    <input type="text" placeholder="Search" />
    <button>
      <i class="fa-light fa-magnifying-glass"></i>
    </button>
  </div>
</div>

<!-- search empty -->
<!-- <div class="text-center mt-5">
  <img src="cook.png" alt="">
  <p class="mt-5">
    Sorry, your search is not on our menu right now.
  </p>

  <div>
    <button class="btn btn-primary btn-block">Explore other foos categories</button>
  </div>
</div> -->

<!-- Search available -->
<div class="row my-4">
  <div class="col-6 mb-4">
    <div class="card position-relative">
      <img src="jollof-rice.png" class="card-img-top" alt="Jollof Rice">
      <div class="d-flex position-absolute bottom-0 start-0 end-0 p-2">
        <div class="quantity-control d-flex justify-content-between align-items-center me-1">
          <button class="btn btn-clear btn-xs" (click)="decrement()">−</button>
          <span class="quantity"> {{ total }} </span>
          <button class="btn  btn-xs btn-clear " (click)="increment()">+</button>
        </div>
        <button class="btn btn-dark btn-sm add-to-cart ms-auto">+</button>
      </div>


    </div>
    <div class="text-center mt-2">
      <h6 class="card-title mb-0">Jollof Rice</h6>
      <p class="card-text">₦ 300 - <span class="text-muted">per spoon</span></p>
    </div>
  </div>

  <div class="col-6 mb-4">
    <div class="card position-relative">
      <img src="chicken-thighs.png" class="card-img-top" alt="Chicken Thighs">
      <div class="d-flex position-absolute bottom-0 start-0 end-0 p-2">
        <div class="quantity-control d-flex justify-content-between align-items-center me-1">
          <button class="btn btn-clear btn-xs">−</button>
          <span class="quantity">9</span>
          <button class="btn  btn-xs btn-clear ">+</button>
        </div>
        <button class="btn btn-dark btn-sm add-to-cart ms-auto">+</button>
      </div>
    </div>
    <div class="text-center mt-2">
      <h6 class="card-title mb-0">Chicken Thighs</h6>
      <p class="card-text">₦ 2,700 - <span class="text-muted">per serving</span></p>
    </div>
  </div>
</div>
