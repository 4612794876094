import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

// Import Pusher library correctly
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: Pusher;
  channel: any;

  constructor(private http: HttpClient) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      wsHost: environment.pusher.host,
      wsPort: environment.pusher.port,
      // encrypted: true,
    });
    this.channel = this.pusher.subscribe('table-notification');
  }

  // Your other methods here
}
