import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppServiceService } from '../../services/app-service.service';
import { EncryptionService } from '../../encryption.service';

@Component({
  selector: 'app-opened-order',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './opened-order.component.html',
  styleUrl: './opened-order.component.scss'
})
export class OpenedOrderComponent {
  constructor(
    private app: AppServiceService, private encryptionService: EncryptionService) { }
  ngOnInit(): void {
    this.fetchActiveOrders();
  }
  activeOrders: any;

  getDeductions(order: any) {
    try {
      return typeof order.deductions === 'string'
        ? JSON.parse(order.deductions)
        : order.deductions;
    } catch (e) {
      return {};
    }
  }

  fetchActiveOrders() {
    let tableNumber = localStorage.getItem('table');
    this.app.fetchActiveOrders(tableNumber ? this.encryptionService.decrypt(tableNumber) : '').subscribe({
      next: (res: any) => {
        this.activeOrders = res.data.map((order: any) => {
          let orderStatus = 0;

          if (order.status === 'accepted') {
            orderStatus = 1;
          } else if (order.status === 'delivered') {
            orderStatus = 2;
          } else if (order.status === 'completed') {
            orderStatus = 3;
          }

          // Parse deductions if it's a string
          const deductions = typeof order.deductions === 'string'
            ? JSON.parse(order.deductions)
            : order.deductions;

          return {
            ...order,
            orderStatus,
            deductions
          };
        });
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        // Optional: Handle any cleanup tasks if needed
      }
    });
  }
}
