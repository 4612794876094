<!-- First: Overlay that appears behind the sidenav -->
<div class="overlay" *ngIf="isSidenavOpen" (click)="toggleSidenav()"></div>

<!-- Second: Sidenav -->
<div id="sidenav" [class.open]="isSidenavOpen" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="toggleSidenav()">&times;</a>
  <a [routerLink]="['/home']">Home</a>
  @if(noTable == false && hasActiveOrders) {
    <a [routerLink]="['/order/opened-order']">
      <img src="./assets/icons/opened-order.svg" alt=""> Opened Orders
    </a>
    <a [routerLink]="['/notifications']">
      <img src="./assets/icons/notification.svg" alt=""> Notifications
    </a>
    <a [routerLink]="['/chat']">
      <img src="./assets/icons/chat.svg" alt=""> Chat Waiter
    </a>
  }
</div>

<!-- Third: Main Content Area -->
<div class="main-content">
  <!-- Navbar -->
  <div class="d-flex justify-content-between align-items-center py-3">
    <a (click)="toggleSidenav()">
      <img src="./assets/icons/menu.svg" alt="">
    </a>
    <div class="ms-auto">
      <button class="btn btn-danger btm-sm btn-buzz me-3" (click)="goToBuzz()" [disabled]="noTable">
        Buzz a Waiter <img src="./assets/icons/buzz-strike.svg" alt="">
      </button>
      <a class="position-relative" [routerLink]="['/cart']">
        <img *ngIf="cartItemCount > 0" src="./assets/icons/cart-item.svg" alt="">
        <img *ngIf="cartItemCount <= 0" src="./assets/icons/cart.svg" alt="">
      </a>
    </div>
  </div>
</div>
