<main class="centered-page">
  <div class="centered-div">
    <h5 class="mb-3">Confirmed</h5>
    <p>Your payment is confirmed and order is sent to
      a waiter</p>
    <div>
      <img src="./assets/icons/check.svg" alt="Check" class="check mt-2">
    </div>
    <br>
    <a [routerLink]="['/home']">
      <button class="btn btn-block btn-primary mt-4">Done</button>
    </a>
  </div>
</main>
