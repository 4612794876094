<app-navbar></app-navbar>
<!-- <div class="order-card my-3">
  <p class="order-number">Order Number</p>
  <p class="order-id">{{ orderNumber }}</p>

  <div class="status-tracker">
    <div class="status-item" [ngClass]="{'active': order.orderStatus >= 1}">
      <div class="circle"></div>
    </div>
    <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 2}"></div>
    <div class="status-item" [ngClass]="{'active': order.orderStatus >= 2}">
      <div class="circle"></div>
    </div>
    <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 3}"></div>
    <div class="status-item" [ngClass]="{'active': order.orderStatus >= 3}">
      <div class="circle"></div>
    </div>
  </div>
</div> -->
<div *ngFor="let order of activeOrders">
  <div class="order-card my-3">
    <p class="order-number">Order Number</p>
    <p class="order-id">{{ order.uid }}</p>
    <div class="row mt-4">
      <div class="col-3">
        <p class="status-name" [ngClass]="{'active': order.orderStatus <= 1}">Ordered</p>
      </div>
      <div class="col-3">
        <p class="status-name" [ngClass]="{'active': order.orderStatus >= 1}">Accepted</p>
      </div>
      <div class="col-3">
        <p class="status-name text-center" [ngClass]="{'active': order.orderStatus >= 2}">Delivered</p>
      </div>
      <div class="col-3">
        <p class="status-name text-end" [ngClass]="{'active': order.orderStatus >= 3}">Completed</p>
      </div>
    </div>
    <div class="status-tracker">
      <div class="status-item" [ngClass]="{'active': order.orderStatus <= 1}">
        <div class="circle"></div>
      </div>
      <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 1}"></div>
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 1}">
        <div class="circle"></div>
      </div>
      <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 2}"></div>
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 2}">
        <div class="circle"></div>
      </div>
      <div class="status-line" [ngClass]="{'fill': order.orderStatus >= 3}"></div>
      <div class="status-item" [ngClass]="{'active': order.orderStatus >= 3}">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>

<!-- Search Bar -->
<!-- Overlay -->
<!-- <div class="search-overlay" *ngIf="showDropdown" (click)="closeDropdown()"></div> -->

<!-- Search Container -->
<div class="search-container">
  <div class="input-group my-1 mb-4">
    <div class="search-boxe w-100">
      <input type="text" [(ngModel)]="searchTerm" (input)="onSearch($event)"
        placeholder="Search for products, brands and categories" class="form-control" />
      <button>
        <img src="./assets/icons/search.svg" alt="Search">
      </button>
    </div>
  </div>

  <!-- Search Dropdown -->
  <div class="search-dropdown" *ngIf="showDropdown">
    <!-- Loading State -->
    <div class="loading-state" *ngIf="isLoading">
      <div class="spinner-border spinner-border-sm" role="status"></div>
      <span>Searching...</span>
    </div>

    <!-- Results -->
    <div class="search-results" *ngIf="!isLoading && searchResults.length > 0">
      <div class="search-item" *ngFor="let item of searchResults" (click)="selectItem(item)">
        <div class="search-item-details">
          <div class="search-item-name">{{item.name}}</div>
          <div class="search-item-meta">
            <span class="search-item-price">{{item.selling_price | currency: '₦'}}</span>
            <span class="search-item-quantity">({{item.quantity}} in stock)</span>
          </div>
          <div class="search-item-category" *ngIf="item.category">
            in {{item.category.name}}
          </div>
        </div>
      </div>
    </div>

    <!-- No Results -->
    <div class="no-results" *ngIf="!isLoading && searchResults.length === 0 && searchTerm">
      No results found for "{{searchTerm}}"
    </div>
  </div>
</div>
<!-- Categories -->
<h5 class="font-weight-bold mb-3">Categories</h5>
<div class="categories">
  <div class="row g-3">
    <!-- Category Items -->
    <div *ngFor="let category of categories; let i = index" class="col-4 col-md-3 text-center mb-1">
      <a [routerLink]="['/category', category.id]">
        <div class="category-item">
          <img [src]="category.image" class="img-fluid fixed-size" alt="">
        </div>
        <p>{{ category.name }}</p>
      </a>
    </div>

  </div>
</div>
